<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Permission User</p>
          </div>
          <div class="col-12 col-xl-6 text-right">
            <button
              class="btn bt-main mx-0 mb-3 mb-xl-0"
              @click="CallModaladdpermission()"
              v-if="$roleaction('Insert Permission')"
            >
              <span v-html="$svg_icon.add"></span> Add Permission
            </button>
          </div>
        </div>
        <hr class="m-0 p-0" />

        <div class="row m-0 p-3">
          <div class="col-12 col-xl-4">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <select class="custom-select">
                  <option>ค้นหาด้วยชื่อ</option>
                </select>
              </div>

              <input
                type="search"
                class="form-control"
                placeholder="ค้นหา / Seach"
                v-model="find"
                v-on:keyup.enter="getpermission()"
              />
              <div class="input-group-append">
                <button class="btn bt-main" @click="getpermission()">
                  ค้นหา
                </button>
              </div>
            </div>
          </div>
          <div class="col-12">
            <b-table
               
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              outlined
              hover
              show-empty
              responsive
              class="font-0-9s"
            >
              <template #cell(row)="row">
                {{ row.index + 1 + (page_num - 1) * page_size }}
              </template>
              <template #cell(map)="row">
                <span
                  v-html="$svg_icon.mapping"
                  v-if="$roleaction('Mapping Permission Role')"
                  type="button"
                  class="mr-2 text-black-50"
                  @click="mapping_permission(row.item)"
                ></span>
              </template>
              <template #cell(action)="row">
                <span
                  v-html="$svg_icon.edit"
                  v-if="$roleaction('Update Permission')"
                  type="button"
                  class="mr-2 text-muted"
                  @click="edit_permission(row.item)"
                ></span>

                <span
                  v-html="$svg_icon.remove"
                  v-if="$roleaction('Delete Permission')"
                  type="button"
                  class="mr-2 text-twd"
                  @click="remove_permission(row.item)"
                ></span>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-6 col-xl-3 input-group-prepend input-group-sm">
                <select class="custom-select" v-model="page_size">
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3">
                <b-pagination
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modaladdpermission
      :key="re_Modal_add_permission"
      @getpermission="getpermission"
    />
    <Modaleditpermission
      :key="re_Modal_ebit_permission"
      :data_edit="data_edit"
      @getpermission="getpermission"
    />
    <Modalmappermissionrole
      :key="re_Modal_map_permission_role"
      :data_map="data_map"
      @getpermission="getpermission"
    />
  </div>
</template>

<script>
import Modaladdpermission from "@/components/AdminIT/Permission_User/Modal_add_permission.vue";
import Modaleditpermission from "@/components/AdminIT/Permission_User/Modal_edit_permission.vue";
import Modalmappermissionrole from "@/components/AdminIT/Permission_User/Modal_map_permission_role.vue";
export default {
  name: "PermissionUser",
  components: {
    Modaladdpermission,
    Modaleditpermission,
    Modalmappermissionrole,
  },
  data() {
    return {
      re_Modal_add_permission: Date.now(),
      re_Modal_ebit_permission: Date.now() + 5,
      re_Modal_map_permission_role: Date.now() + 10,
      data_edit: "",
      data_map: "",
      find: "",
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      totalRows: 1,

      fields: [
        { key: "row", label: "Row Number" },
        { key: "permission_name", label: "Permission Name" },
        { key: "create_date", label: "Create Date" },
        { key: "map", label: "Rule Mapping", class: "text-center" },
        { key: "action", label: "Action", class: "text-center" },
      ],
      items: [],
    };
  },
  watch: {
    page_num: function () {
      this.getpermission();
    },
    page_size: function () {
      this.page_num = 1;
      this.getpermission();
    },
  },
  computed: {},
  mounted() {
    this.getpermission();
  },
  methods: {
    // async tee() {
    //   let data = {};
    //   let getAPI = await this.$serviceAPI.call_API(
    //     "post",
    //     `adminit/tee`,
    //     data,
    //     1
    //   );

    //   console.log(getAPI.data);

    //   try {
    //   } catch (error) {
    //     this.$serviceMain.showErrorAlert(
    //       this,
    //       error.message == undefined ? error : error.message
    //     );
    //   }
    // },
    async mapping_permission(data) {
      try {
        this.data_map = data;
        this.re_Modal_map_permission_role = Date.now();
        setTimeout(() => {
          this.$bvModal.show("Modal_map_permission_role");
        }, 100);
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async remove_permission(data) {
      try {
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `คุณต้องการลบ ${data.permission_name} / You need to delete ${data.permission_name}?`
        );

        if (confirm) {
          let data_body = {
            permission_id: data.permission_id,
          };

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `adminit/removepermission`,
            data_body,
            1
          );

          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.getpermission();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async edit_permission(data) {
      try {
        this.data_edit = data;
        this.re_Modal_ebit_permission = Date.now();
        setTimeout(() => {
          this.$bvModal.show("Modal_edit_permission");
        }, 100);
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getpermission() {
      let data = {
        find: this.find,
        page_size: this.page_size,
        page_num: this.page_num,
      };
      let getAPI = await this.$serviceAPI.call_API(
        "post",
        `adminit/getpermission`,
        data,
        1
      );

      this.items = getAPI.data;
      this.totalRows = getAPI.data[0].totalRow;

      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    CallModaladdpermission() {
      this.re_Modal_add_permission = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modal_add_permission");
      }, 100);
    },
    changepage(e) {
      this.page_size = e.target.value;
      this.page_num = 0;
      this.getpermission();
    },
  },
};
</script>

<style>
</style>