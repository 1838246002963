<template>
  <b-modal
    id="Modal_map_permission_role"
    hide-footer
    title="Mapping Permission/Role"
    size="lg"
  >
    <div class="row">
      <!-- {{ data_edit }} -->
      <div class="col-6 col-lg-4" v-for="(e, i) in data_role" :key="i">
        <div class="form-check form-check-inline mb-3">
          <input
            class="form-check-input"
            type="checkbox"
            :value="e.role_id"
            v-model="role_now"
            :id="e.role_name"
          />
          <label class="form-check-label" :for="e.role_name">{{ e.role_name }}</label>
        </div>
      </div>
      <div class="col-12">
        <button class="btn bt-main w-100" @click="confirm()">MAPPING</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Modal_map_permission_role",
  props: ["data_map"],
  data() {
    return {
      data_role: [],
      role_now: [],
    };
  },
  watch: {
    props_data_map: function (v) {},
    // role_now: function () {
    //   console.log(this.role_now);
    // },
  },
  computed: {
    props_data_edit() {
      return this.data_map;
    },
  },
  mounted() {
    this.getrole();
    this.getmappingbypermission();
  },
  methods: {
    async getrole() {
      let data = {};
      let getAPI = await this.$serviceAPI.call_API(
        "post",
        `adminit/getallrole`,
        data,
        1
      );

      this.data_role = getAPI.data;

      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getmappingbypermission() {
      try {
        if (this.data_map.permission_id !== undefined) {
          let data = { permission_id: this.data_map.permission_id };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `adminit/getmappingbypermission`,
            data,
            1
          );

          var role_id = getAPI.data.map(function (e) {
            return e.role_id;
          });
          this.role_now = role_id;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    validate() {
      if (this.role_now.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    async confirm() {
      try {
        if (this.validate()) {
          throw `กรุณาระบุชื่อบทบาท / Please Enter role Name`;
        }
        let data = {
          permission_id: this.data_map.permission_id,
          role_id: this.role_now,
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `adminit/mapping`,
          data,
          1
        );

        this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        this.redata();
        this.hide();
        this.$emit("getpermission");
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.data_role = [];
      this.role_now = [];
    },
    hide() {
      this.$bvModal.hide("Modal_map_permission_role");
    },
  },
};
</script>

<style>
</style>